











































import Vue from 'vue';

import mixins from 'vue-typed-mixins';
import { externalHooks } from '@/components/mixins/externalHooks';
import { nodeBase } from '@/components/mixins/nodeBase';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { workflowHelpers } from '@/components/mixins/workflowHelpers';
import { getStyleTokenValue, isNumber, isString } from './helpers';
import { INodeUi, XYPosition } from '@/Interface';

import {
	INodeTypeDescription,
} from 'n8n-workflow';
import { QUICKSTART_NOTE_NAME } from '@/constants';

export default mixins(externalHooks, nodeBase, nodeHelpers, workflowHelpers).extend({
	name: 'Sticky',
	props: {
		nodeViewScale: {
			type: Number,
		},
		gridSize: {
			type: Number,
		},
	},
	mounted() {
		this.$externalHooks().run('sticky.mounted', { stickyRef: this.$refs['sticky'] });
	},
	computed: {
		defaultText (): string {
			if (!this.nodeType) {
				return '';
			}
			const properties = this.nodeType.properties;
			const content = properties.find((property) => property.name === 'content');

			return content && isString(content.default) ? content.default : '';
		},
		isSelected (): boolean {
			return this.$store.getters.getSelectedNodes.find((node: INodeUi) => node.name === this.data.name);
		},
		nodeType (): INodeTypeDescription | null {
			return this.data && this.$store.getters['nodeTypes/getNodeType'](this.data.type, this.data.typeVersion);
		},
		node (): INodeUi | undefined { // same as this.data but reactive..
			return this.$store.getters.nodesByName[this.name] as INodeUi | undefined;
		},
		position (): XYPosition {
			if (this.node) {
				return this.node.position;
			} else {
				return [0, 0];
			}
		},
		height(): number {
			return this.node && isNumber(this.node.parameters.height)? this.node.parameters.height : 0;
		},
		width(): number {
			return this.node && isNumber(this.node.parameters.width)? this.node.parameters.width : 0;
		},
		stickySize(): object {
			const returnStyles: {
				[key: string]: string | number;
			} = {
				height: this.height + 'px',
				width: this.width + 'px',
			};

			return returnStyles;
		},
		stickyPosition (): object {
			const returnStyles: {
				[key: string]: string | number;
			} = {
				left: this.position[0] + 'px',
				top: this.position[1] + 'px',
				zIndex: this.isActive ? 9999999 : -1 * Math.floor((this.height * this.width) / 1000),
			};

			return returnStyles;
		},
		showActions(): boolean {
			return !(this.hideActions || this.isReadOnly || this.workflowRunning || this.isResizing);
		},
		workflowRunning (): boolean {
			return this.$store.getters.isActionActive('workflowRunning');
		},
	},
	data () {
		return {
			isResizing: false,
			isTouchActive: false,
		};
	},
	methods: {
		deleteNode () {
			Vue.nextTick(() => {
				// Wait a tick else vue causes problems because the data is gone
				this.$emit('removeNode', this.data.name);
			});
		},
		onEdit(edit: boolean) {
			if (edit && !this.isActive && this.node) {
				this.$store.commit('setActiveNode', this.node.name);
			}
			else if (this.isActive && !edit) {
				this.$store.commit('setActiveNode', null);
			}
		},
		onMarkdownClick ( link:HTMLAnchorElement, event: Event ) {
			if (link) {
				const isOnboardingNote = this.name === QUICKSTART_NOTE_NAME;
				const isWelcomeVideo = link.querySelector('img[alt="n8n quickstart video"');
				const type = isOnboardingNote && isWelcomeVideo ? 'welcome_video' : isOnboardingNote && link.getAttribute('href') === '/templates' ? 'templates' : 'other';

				this.$telemetry.track('User clicked note link', { type } );
			}
		},
		onInputChange(content: string) {
			this.setParameters({content});
		},
		onResizeStart() {
			this.isResizing = true;
			if (!this.isSelected && this.node) {
				this.$emit('nodeSelected', this.node.name, false, true);
			}
			if (this.node) {
				this.instance.destroyDraggable(this.node.id); // todo avoid destroying if possible
			}
		},
		onResize({height, width, dX, dY}:  { width: number, height: number, dX: number, dY: number }) {
			if (!this.node) {
				return;
			}
			if (dX !== 0 || dY !== 0) {
				this.setPosition([this.node.position[0] + (dX || 0), this.node.position[1] + (dY || 0)]);
			}

			this.setParameters({ height, width });
		},
		onResizeEnd() {
			this.isResizing = false;
			this.__makeInstanceDraggable(this.data);
		},
		setParameters(params: {content?: string, height?: number, width?: number}) {
			if (this.node) {
				const nodeParameters = {
					content: isString(params.content) ? params.content : this.node.parameters.content,
					height: isNumber(params.height) ? params.height : this.node.parameters.height,
					width: isNumber(params.width) ? params.width : this.node.parameters.width,
				};

				const updateInformation = {
					name: this.node.name,
					value: nodeParameters,
				};

				this.$store.commit('setNodeParameters', updateInformation);
			}
		},
		setPosition(position: XYPosition) {
			if (!this.node) {
				return;
			}

			const updateInformation = {
				name: this.node.name,
				properties: {
					position,
				},
			};

			this.$store.commit('updateNodeProperties', updateInformation);
		},
		touchStart () {
			if (this.isTouchDevice === true && this.isMacOs === false && this.isTouchActive === false) {
				this.isTouchActive = true;
				setTimeout(() => {
					this.isTouchActive = false;
				}, 2000);
			}
		},
	},
});

