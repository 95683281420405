












































import ExpressionInput from '@/components/ExpressionInput.vue';
import VariableSelector from '@/components/VariableSelector.vue';

import { IVariableItemSelected } from '@/Interface';

import { externalHooks } from '@/components/mixins/externalHooks';
import { genericHelpers } from '@/components/mixins/genericHelpers';

import mixins from 'vue-typed-mixins';
import { hasExpressionMapping } from './helpers';

export default mixins(
	externalHooks,
	genericHelpers,
).extend({
	name: 'ExpressionEdit',
	props: [
		'dialogVisible',
		'parameter',
		'path',
		'value',
		'eventSource',
	],
	components: {
		ExpressionInput,
		VariableSelector,
	},
	data () {
		return {
			displayValue: '',
			latestValue: '',
		};
	},
	updated() {
		if (this.$refs.expressionInput && this.$refs.expressionOutput) {
			this.$externalHooks().run(
				'expressionEdit.mounted',
				{
					expressionInputRef: this.$refs.expressionInput,
					expressionOutputRef: this.$refs.expressionOutput,
				},
			);
		}
	},
	methods: {
		valueChanged (value: string, forceUpdate = false) {
			this.latestValue = value;

			if (forceUpdate === true) {
				this.updateDisplayValue();
				this.$emit('valueChanged', this.latestValue);
			} else {
				this.callDebounced('updateDisplayValue', { debounceTime: 500 });
			}
		},

		updateDisplayValue () {
			this.displayValue = this.latestValue;
		},

		closeDialog () {
			if (this.latestValue !== this.value) {
				// Handle the close externally as the visible parameter is an external prop
				// and is so not allowed to be changed here.
				this.$emit('valueChanged', this.latestValue);
			}
			this.$emit('closeDialog');
			return false;
		},

		itemSelected (eventData: IVariableItemSelected) {
			(this.$refs.inputFieldExpression as any).itemSelected(eventData); // tslint:disable-line:no-any
			this.$externalHooks().run('expressionEdit.itemSelected', { parameter: this.parameter, value: this.value, selectedItem: eventData });

			const trackProperties: {
				event_version: string;
				node_type_dest: string;
				node_type_source?: string;
				parameter_name_dest: string;
				parameter_name_source?: string;
				variable_type?: string;
				is_immediate_input: boolean;
				variable_expression: string;
				node_name: string;
			} = {
				event_version: '2',
				node_type_dest: this.$store.getters.activeNode.type,
				parameter_name_dest: this.parameter.displayName,
				is_immediate_input: false,
				variable_expression: eventData.variable,
				node_name: this.$store.getters.activeNode.name,
			};

			if (eventData.variable) {
				let splitVar = eventData.variable.split('.');

				if (eventData.variable.startsWith('Object.keys')) {
					splitVar = eventData.variable.split('(')[1].split(')')[0].split('.');
					trackProperties.variable_type = 'Keys';
				} else if (eventData.variable.startsWith('Object.values')) {
					splitVar = eventData.variable.split('(')[1].split(')')[0].split('.');
					trackProperties.variable_type = 'Values';
				} else {
					trackProperties.variable_type = 'Raw value';
				}

				if (splitVar[0].startsWith('$node')) {
					const sourceNodeName = splitVar[0].split('"')[1];
					trackProperties.node_type_source = this.$store.getters.getNodeByName(sourceNodeName).type;
					const nodeConnections: Array<Array<{ node: string }>> = this.$store.getters.outgoingConnectionsByNodeName(sourceNodeName).main;
					trackProperties.is_immediate_input = (nodeConnections && nodeConnections[0] && !!nodeConnections[0].find(({ node }) => node === this.$store.getters.activeNode.name)) ? true : false;

					if (splitVar[1].startsWith('parameter')) {
						trackProperties.parameter_name_source = splitVar[1].split('"')[1];
					}

				} else {
					trackProperties.is_immediate_input = true;

					if(splitVar[0].startsWith('$parameter')) {
						trackProperties.parameter_name_source = splitVar[0].split('"')[1];
					}
				}
			}

			this.$telemetry.track('User inserted item from Expression Editor variable selector', trackProperties);
		},
	},
	watch: {
		dialogVisible (newValue) {
			this.displayValue = this.value;
			this.latestValue = this.value;

			const resolvedExpressionValue = this.$refs.expressionResult && (this.$refs.expressionResult as any).getValue() || undefined;  // tslint:disable-line:no-any
			this.$externalHooks().run('expressionEdit.dialogVisibleChanged', { dialogVisible: newValue, parameter: this.parameter, value: this.value, resolvedExpressionValue });

			if (!newValue) {
				const telemetryPayload = {
					empty_expression: (this.value === '=') || (this.value === '={{}}') || !this.value,
					workflow_id: this.$store.getters.workflowId,
					source: this.eventSource,
					session_id: this.$store.getters['ui/ndvSessionId'],
					has_parameter: this.value.includes('$parameter'),
					has_mapping: hasExpressionMapping(this.value),
				};
				this.$telemetry.track('User closed Expression Editor', telemetryPayload);
				this.$externalHooks().run('expressionEdit.closeDialog', telemetryPayload);
			}
		},
	},
});
