













































































































































































































































































































































//@ts-ignore
import VueJsonPretty from 'vue-json-pretty';
import {
	GenericValue,
	IBinaryData,
	IBinaryKeyData,
	IDataObject,
	INodeExecutionData,
	INodeTypeDescription,
	IRunData,
	IRunExecutionData,
} from 'n8n-workflow';

import {
	IBinaryDisplayData,
	IExecutionResponse,
	INodeUi,
	IRunDataDisplayMode,
	ITab,
	ITableData,
} from '@/Interface';

import {
	DATA_PINNING_DOCS_URL,
	DATA_EDITING_DOCS_URL,
	LOCAL_STORAGE_PIN_DATA_DISCOVERY_NDV_FLAG,
	LOCAL_STORAGE_PIN_DATA_DISCOVERY_CANVAS_FLAG,
	MAX_DISPLAY_DATA_SIZE,
	MAX_DISPLAY_ITEMS_AUTO_ALL,
	TEST_PIN_DATA,
} from '@/constants';

import BinaryDataDisplay from '@/components/BinaryDataDisplay.vue';
import WarningTooltip from '@/components/WarningTooltip.vue';
import NodeErrorView from '@/components/Error/NodeErrorView.vue';

import { copyPaste } from '@/components/mixins/copyPaste';
import { externalHooks } from "@/components/mixins/externalHooks";
import { genericHelpers } from '@/components/mixins/genericHelpers';
import { nodeHelpers } from '@/components/mixins/nodeHelpers';
import { pinData } from '@/components/mixins/pinData';

import mixins from 'vue-typed-mixins';

import { saveAs } from 'file-saver';
import { CodeEditor } from "@/components/forms";
import { dataPinningEventBus } from '../event-bus/data-pinning-event-bus';
import { stringSizeInBytes } from './helpers';
import RunDataTable from './RunDataTable.vue';
import { isJsonKeyObject } from '@/utils';

// A path that does not exist so that nothing is selected by default
const deselectedPlaceholder = '_!^&*';

export type EnterEditModeArgs = {
	origin: 'editIconButton' | 'insertTestDataLink',
};

export default mixins(
	copyPaste,
	externalHooks,
	genericHelpers,
	nodeHelpers,
	pinData,
)
	.extend({
		name: 'RunData',
		components: {
			BinaryDataDisplay,
			NodeErrorView,
			VueJsonPretty,
			WarningTooltip,
			CodeEditor,
			RunDataTable,
		},
		props: {
			nodeUi: {
			}, // INodeUi | null
			runIndex: {
				type: Number,
			},
			linkedRuns: {
				type: Boolean,
			},
			canLinkRuns: {
				type: Boolean,
			},
			tooMuchDataTitle: {
				type: String,
			},
			noDataInBranchMessage: {
				type: String,
			},
			isExecuting: {
				type: Boolean,
			},
			executingMessage: {
				type: String,
			},
			sessionId: {
				type: String,
			},
			paneType: {
				type: String,
			},
			overrideOutputs: {
				type: Array,
			},
			mappingEnabled: {
				type: Boolean,
			},
			distanceFromActive: {
				type: Number,
			},
			showMappingHint: {
				type: Boolean,
			},
		},
		data () {
			return {
				binaryDataPreviewActive: false,
				dataSize: 0,
				deselectedPlaceholder,
				selectedOutput: {
					value: '' as object | number | string,
					path: deselectedPlaceholder,
				},
				showData: false,
				outputIndex: 0,
				binaryDataDisplayVisible: false,
				binaryDataDisplayData: null as IBinaryDisplayData | null,

				MAX_DISPLAY_DATA_SIZE,
				MAX_DISPLAY_ITEMS_AUTO_ALL,
				currentPage: 1,
				pageSize: 10,
				pageSizes: [10, 25, 50, 100],
				copyDropdownOpen: false,
				eventBus: dataPinningEventBus,

				pinDataDiscoveryTooltipVisible: false,
				isControlledPinDataTooltip: false,
			};
		},
		mounted() {
			this.init();

			if (this.paneType === 'output') {
				this.eventBus.$on('data-pinning-error', this.onDataPinningError);
				this.eventBus.$on('data-unpinning', this.onDataUnpinning);

				const hasSeenPinDataTooltip = localStorage.getItem(LOCAL_STORAGE_PIN_DATA_DISCOVERY_NDV_FLAG);
				if (!hasSeenPinDataTooltip) {
					this.showPinDataDiscoveryTooltip(this.jsonData);
				}
			}
		},
		updated() {
			this.$nextTick(() => {
				const jsonValues = this.$el.querySelectorAll('.vjs-value');
				const tableRows = this.$el.querySelectorAll('tbody tr');

				const elements = [...jsonValues, ...tableRows].reduce<Element[]>((acc, cur) => [...acc, cur], []);

				if (elements.length > 0) {
					this.$externalHooks().run('runData.updated', { elements });
				}
			});
		},
		destroyed() {
			this.hidePinDataDiscoveryTooltip();
			this.eventBus.$off('data-pinning-error', this.onDataPinningError);
			this.eventBus.$off('data-unpinning', this.onDataUnpinning);
		},
		computed: {
			activeNode(): INodeUi {
				return this.$store.getters.activeNode;
			},
			dataPinningDocsUrl(): string {
				return DATA_PINNING_DOCS_URL;
			},
			dataEditingDocsUrl(): string{
				return DATA_EDITING_DOCS_URL;
			},
			displayMode(): IRunDataDisplayMode {
				return this.$store.getters['ui/getPanelDisplayMode'](this.paneType);
			},
			node(): INodeUi | null {
				return (this.nodeUi as INodeUi | null) || null;
			},
			nodeType (): INodeTypeDescription | null {
				if (this.node) {
					return this.$store.getters['nodeTypes/getNodeType'](this.node.type, this.node.typeVersion);
				}
				return null;
			},
			isTriggerNode (): boolean {
				return !!(this.nodeType && this.nodeType.group.includes('trigger'));
			},
			canPinData (): boolean {
				return this.paneType === 'output' &&
					this.isPinDataNodeType &&
					!(this.binaryData && this.binaryData.length > 0);
			},
			buttons(): Array<{label: string, value: string}> {
				const defaults = [
					{ label: this.$locale.baseText('runData.table'), value: 'table'},
					{ label: this.$locale.baseText('runData.json'), value: 'json'},
				];
				if (this.binaryData.length) {
					return [ ...defaults,
						{ label: this.$locale.baseText('runData.binary'), value: 'binary'},
					];
				}

				return defaults;
			},
			hasNodeRun(): boolean {
				return Boolean(!this.isExecuting && this.node && (this.workflowRunData && this.workflowRunData.hasOwnProperty(this.node.name) || this.hasPinData));
			},
			hasRunError(): boolean {
				return Boolean(this.node && this.workflowRunData && this.workflowRunData[this.node.name] && this.workflowRunData[this.node.name][this.runIndex] && this.workflowRunData[this.node.name][this.runIndex].error);
			},
			workflowExecution (): IExecutionResponse | null {
				return this.$store.getters.getWorkflowExecution;
			},
			workflowRunData (): IRunData | null {
				if (this.workflowExecution === null) {
					return null;
				}
				const executionData: IRunExecutionData = this.workflowExecution.data;
				if (executionData && executionData.resultData) {
					return executionData.resultData.runData;
				}
				return null;
			},
			dataCount (): number {
				return this.getDataCount(this.runIndex, this.currentOutputIndex);
			},
			dataSizeInMB(): string {
				return (this.dataSize / 1024 / 1000).toLocaleString();
			},
			maxOutputIndex (): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length < this.runIndex) {
					return 0;
				}

				if (runData[this.node.name][this.runIndex]) {
					const taskData = runData[this.node.name][this.runIndex].data;
					if (taskData && taskData.main) {
						return taskData.main.length - 1;
					}
				}

				return 0;
			},
			maxRunIndex (): number {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length) {
					return runData[this.node.name].length - 1;
				}

				return 0;
			},
			rawInputData (): INodeExecutionData[] {
				let inputData: INodeExecutionData[] = [];

				if (this.node) {
					inputData = this.getNodeInputData(this.node, this.runIndex, this.currentOutputIndex);
				}

				if (inputData.length === 0 || !Array.isArray(inputData)) {
					return [];
				}

				return inputData;
			},
			inputData (): INodeExecutionData[] {
				let inputData = this.rawInputData;

				if (this.node && this.pinData) {
					inputData = Array.isArray(this.pinData)
						? this.pinData.map((value) => ({
							json: value,
						}))
						: [{
							json: this.pinData,
						}];
				}

				const offset = this.pageSize * (this.currentPage - 1);
				inputData = inputData.slice(offset, offset + this.pageSize);

				return inputData;
			},
			jsonData (): IDataObject[] {
				return this.convertToJson(this.inputData);
			},
			binaryData (): IBinaryKeyData[] {
				if (!this.node) {
					return [];
				}

				const binaryData = this.getBinaryData(this.workflowRunData, this.node.name, this.runIndex, this.currentOutputIndex);
				return binaryData.filter((data) => Boolean(data && Object.keys(data).length));
			},
			currentOutputIndex(): number {
				if (this.overrideOutputs && this.overrideOutputs.length && !this.overrideOutputs.includes(this.outputIndex)) {
					return this.overrideOutputs[0] as number;
				}

				return this.outputIndex;
			},
			branches (): ITab[] {
				function capitalize(name: string) {
					return name.charAt(0).toLocaleUpperCase() + name.slice(1);
				}
				const branches: ITab[] = [];
				for (let i = 0; i <= this.maxOutputIndex; i++) {
					if (this.overrideOutputs && !this.overrideOutputs.includes(i)) {
						continue;
					}
					const itemsCount = this.getDataCount(this.runIndex, i);
					const items = this.$locale.baseText('ndv.output.items', {adjustToNumber: itemsCount});
					let outputName = this.getOutputName(i);
					if (`${outputName}` === `${i}`) {
						outputName = `${this.$locale.baseText('ndv.output')} ${outputName}`;
					}
					else {
						outputName = capitalize(`${this.getOutputName(i)} ${this.$locale.baseText('ndv.output.branch')}`);
					}
					branches.push({
						label: itemsCount ? `${outputName} (${itemsCount} ${items})` : outputName,
						value: i,
					});
				}
				return branches;
			},
			editMode(): { enabled: boolean; value: string; } {
				return this.paneType === 'output'
					? this.$store.getters['ui/outputPanelEditMode']
					: { enabled: false, value: '' };
			},
		},
		methods: {
			onClickDataPinningDocsLink() {
				this.$telemetry.track('User clicked ndv link', {
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					node_type: this.activeNode.type,
					pane: 'output',
					type: 'data-pinning-docs',
				});
			},
			showPinDataDiscoveryTooltip(value: IDataObject[]) {
				if (!this.isTriggerNode) {
					return;
				}

				if (value && value.length > 0) {
					this.pinDataDiscoveryComplete();

					setTimeout(() => {
						this.isControlledPinDataTooltip = true;
						this.pinDataDiscoveryTooltipVisible = true;
						this.eventBus.$emit('data-pinning-discovery', { isTooltipVisible: true });
					}, 500); // Wait for NDV to open
				}
			},
			hidePinDataDiscoveryTooltip() {
				if (this.pinDataDiscoveryTooltipVisible) {
					this.isControlledPinDataTooltip = false;
					this.pinDataDiscoveryTooltipVisible = false;
					this.eventBus.$emit('data-pinning-discovery', { isTooltipVisible: false });
				}
			},
			pinDataDiscoveryComplete() {
				localStorage.setItem(LOCAL_STORAGE_PIN_DATA_DISCOVERY_NDV_FLAG, 'true');
				localStorage.setItem(LOCAL_STORAGE_PIN_DATA_DISCOVERY_CANVAS_FLAG, 'true');
			},
			enterEditMode({ origin }: EnterEditModeArgs) {
				const inputData = this.pinData
					? this.clearJsonKey(this.pinData)
					: this.convertToJson(this.rawInputData);

				const data = inputData.length > 0
					? inputData
					: TEST_PIN_DATA;

				this.$store.commit('ui/setOutputPanelEditModeEnabled', true);
				this.$store.commit('ui/setOutputPanelEditModeValue', JSON.stringify(data, null, 2));

				this.$telemetry.track('User opened ndv edit state', {
					node_type: this.activeNode.type,
					click_type: origin === 'editIconButton' ? 'button' : 'link',
					session_id: this.sessionId,
					run_index: this.runIndex,
					is_output_present: this.hasNodeRun || this.hasPinData,
					view: !this.hasNodeRun && !this.hasPinData ? 'undefined' : this.displayMode,
					is_data_pinned: this.hasPinData,
				});
			},
			onClickCancelEdit() {
				this.$store.commit('ui/setOutputPanelEditModeEnabled', false);
				this.$store.commit('ui/setOutputPanelEditModeValue', '');
				this.onExitEditMode({ type: 'cancel' });
			},
			onClickSaveEdit() {
				const { value } = this.editMode;

				this.clearAllStickyNotifications();

				if (!this.isValidPinDataSize(value)) {
					this.onDataPinningError({ errorType: 'data-too-large', source: 'save-edit' });
					return;
				}

				if (!this.isValidPinDataJSON(value)) {
					this.onDataPinningError({ errorType: 'invalid-json', source: 'save-edit' });
					return;
				}

				this.$store.commit('ui/setOutputPanelEditModeEnabled', false);
				this.$store.commit('pinData', { node: this.node, data: this.clearJsonKey(value) });

				this.onDataPinningSuccess({ source: 'save-edit' });

				this.onExitEditMode({ type: 'save' });
			},
			clearJsonKey(userInput: string | object) {
				const parsedUserInput = typeof userInput === 'string' ? JSON.parse(userInput) : userInput;

				if (!Array.isArray(parsedUserInput)) return parsedUserInput;

				return parsedUserInput.map(item => isJsonKeyObject(item) ? item.json : item);
			},
			onExitEditMode({ type }: { type: 'save' | 'cancel' }) {
				this.$telemetry.track('User closed ndv edit state', {
					node_type: this.activeNode.type,
					session_id: this.sessionId,
					run_index: this.runIndex,
					view: this.displayMode,
					type,
				});
			},
			onDataUnpinning(
				{ source }: { source: 'banner-link' | 'pin-icon-click' | 'unpin-and-execute-modal' },
			) {
				this.$telemetry.track('User unpinned ndv data', {
					node_type: this.activeNode.type,
					session_id: this.sessionId,
					run_index: this.runIndex,
					source,
					data_size: stringSizeInBytes(this.pinData),
				});
			},
			onDataPinningSuccess({ source }: { source: 'pin-icon-click' | 'save-edit' }) {
				const telemetryPayload = {
					pinning_source: source,
					node_type: this.activeNode.type,
					session_id: this.sessionId,
					data_size: stringSizeInBytes(this.pinData),
					view: this.displayMode,
					run_index: this.runIndex,
				};
				this.$externalHooks().run('runData.onDataPinningSuccess', telemetryPayload);
				this.$telemetry.track('Ndv data pinning success', telemetryPayload);
			},
			onDataPinningError(
				{ errorType, source }: {
					errorType: 'data-too-large' | 'invalid-json',
					source: 'on-ndv-close-modal' | 'pin-icon-click' | 'save-edit'
				},
			) {
				this.$telemetry.track('Ndv data pinning failure', {
					pinning_source: source,
					node_type: this.activeNode.type,
					session_id: this.sessionId,
					data_size: stringSizeInBytes(this.pinData),
					view: this.displayMode,
					run_index: this.runIndex,
					error_type: errorType,
				});
			},
			async onTogglePinData(
				{ source }: { source: 'banner-link' | 'pin-icon-click' | 'unpin-and-execute-modal' },
			) {
				if (source === 'pin-icon-click') {
					const telemetryPayload = {
						node_type: this.activeNode.type,
						session_id: this.sessionId,
						run_index: this.runIndex,
						view: !this.hasNodeRun && !this.hasPinData ? 'none' : this.displayMode,
					};

					this.$externalHooks().run('runData.onTogglePinData', telemetryPayload);
					this.$telemetry.track('User clicked pin data icon', telemetryPayload);
				}

				this.updateNodeParameterIssues(this.node);

				if (this.hasPinData) {
					this.onDataUnpinning({ source });
					this.$store.commit('unpinData', { node: this.node });
					return;
				}

				const data = this.convertToJson(this.rawInputData);

				if (!this.isValidPinDataSize(data)) {
					this.onDataPinningError({ errorType: 'data-too-large', source: 'pin-icon-click' });
					return;
				}

				this.onDataPinningSuccess({ source: 'save-edit' });

				this.$store.commit('pinData', { node: this.node, data });

				if (this.maxRunIndex > 0) {
					this.$showToast({
						title: this.$locale.baseText('ndv.pinData.pin.multipleRuns.title', {
							interpolate: {
								index: `${this.runIndex}`,
							},
						}),
						message: this.$locale.baseText('ndv.pinData.pin.multipleRuns.description'),
						type: 'success',
						duration: 2000,
					});
				}

				this.hidePinDataDiscoveryTooltip();
				this.pinDataDiscoveryComplete();
			},
			switchToBinary() {
				this.onDisplayModeChange('binary');
			},
			onBranchChange(value: number) {
				this.outputIndex = value;

				this.$telemetry.track('User changed ndv branch', {
					session_id: this.sessionId,
					branch_index: value,
					node_type: this.activeNode.type,
					node_type_input_selection: this.nodeType? this.nodeType.name: '',
					pane: this.paneType,
				});
			},
			showTooMuchData() {
				this.showData = true;
				this.$telemetry.track('User clicked ndv button', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: this.paneType,
					type: 'showTooMuchData',
				});
			},
			linkRun() {
				this.$emit('linkRun');
			},
			unlinkRun() {
				this.$emit('unlinkRun');
			},
			onCurrentPageChange() {
				this.$telemetry.track('User changed ndv page', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: this.paneType,
					page_selected: this.currentPage,
					page_size: this.pageSize,
					items_total: this.dataCount,
				});
			},
			onPageSizeChange(pageSize: number) {
				this.pageSize = pageSize;
				const maxPage = Math.ceil(this.dataCount / this.pageSize);
				if (maxPage < this.currentPage) {
					this.currentPage = maxPage;
				}

				this.$telemetry.track('User changed ndv page size', {
					node_type: this.activeNode.type,
					workflow_id: this.$store.getters.workflowId,
					session_id: this.sessionId,
					pane: this.paneType,
					page_selected: this.currentPage,
					page_size: this.pageSize,
					items_total: this.dataCount,
				});
			},
			onDisplayModeChange(displayMode: IRunDataDisplayMode) {
				const previous = this.displayMode;
				this.$store.commit('ui/setPanelDisplayMode', {pane: this.paneType, mode: displayMode});

				const dataContainer = this.$refs.dataContainer;
				if (dataContainer) {
					const dataDisplay = (dataContainer as Element).children[0];

					if (dataDisplay){
						dataDisplay.scrollTo(0, 0);
					}
				}

				this.closeBinaryDataDisplay();
				this.$externalHooks().run('runData.displayModeChanged', { newValue: displayMode, oldValue: previous });
				if(this.activeNode) {
					this.$telemetry.track('User changed ndv item view', {
						previous_view: previous,
						new_view: displayMode,
						node_type: this.activeNode.type,
						workflow_id: this.$store.getters.workflowId,
						session_id: this.sessionId,
						pane: this.paneType,
					});
				}
			},
			getRunLabel(option: number) {
				let itemsCount = 0;
				for (let i = 0; i <= this.maxOutputIndex; i++) {
					itemsCount += this.getDataCount(option - 1, i);
				}
				const items = this.$locale.baseText('ndv.output.items', {adjustToNumber: itemsCount});
				const itemsLabel = itemsCount > 0 ? ` (${itemsCount} ${items})` : '';
				return option + this.$locale.baseText('ndv.output.of') + (this.maxRunIndex+1) + itemsLabel;
			},
			getDataCount(runIndex: number, outputIndex: number) {
				if (this.node === null) {
					return 0;
				}

				const runData: IRunData | null = this.workflowRunData;

				if (runData === null || !runData.hasOwnProperty(this.node.name)) {
					return 0;
				}

				if (runData[this.node.name].length <= runIndex) {
					return 0;
				}

				if (runData[this.node.name][runIndex].hasOwnProperty('error')) {
					return 1;
				}

				if (!runData[this.node.name][runIndex].hasOwnProperty('data') ||
					runData[this.node.name][runIndex].data === undefined
				) {
					return 0;
				}

				const inputData = this.getMainInputData(runData[this.node.name][runIndex].data!, outputIndex);

				return inputData.length;
			},
			init() {
				// Reset the selected output index every time another node gets selected
				this.outputIndex = 0;
				this.refreshDataSize();
				this.closeBinaryDataDisplay();
				if (this.binaryData.length > 0) {
					this.$store.commit('ui/setPanelDisplayMode', {pane: this.paneType, mode: 'binary'});
				}
				else if (this.displayMode === 'binary') {
					this.$store.commit('ui/setPanelDisplayMode', {pane: this.paneType, mode: 'table'});
				}
			},
			closeBinaryDataDisplay () {
				this.binaryDataDisplayVisible = false;
				this.binaryDataDisplayData = null;
			},
			convertToJson (inputData: INodeExecutionData[]): IDataObject[] {
				const returnData: IDataObject[] = [];
				inputData.forEach((data) => {
					if (!data.hasOwnProperty('json')) {
						return;
					}
					returnData.push(data.json);
				});

				return returnData;
			},
			clearExecutionData () {
				this.$store.commit('setWorkflowExecutionData', null);
				this.updateNodesExecutionIssues();
			},
			dataItemClicked (path: string, data: object | number | string) {
				this.selectedOutput.value = data;
			},
			isDownloadable (index: number, key: string): boolean {
				const binaryDataItem: IBinaryData = this.binaryData[index][key];
				return !!(binaryDataItem.mimeType && binaryDataItem.fileName);
			},
			async downloadBinaryData (index: number, key: string) {
				const binaryDataItem: IBinaryData = this.binaryData[index][key];

				let bufferString = 'data:' + binaryDataItem.mimeType + ';base64,';
				if(binaryDataItem.id) {
					bufferString += await this.restApi().getBinaryBufferString(binaryDataItem.id);
				} else {
					bufferString += binaryDataItem.data;
				}

				const data = await fetch(bufferString);
				const blob = await data.blob();
				saveAs(blob, binaryDataItem.fileName);
			},
			displayBinaryData (index: number, key: string) {
				this.binaryDataDisplayVisible = true;

				this.binaryDataDisplayData = {
					node: this.node!.name,
					runIndex: this.runIndex,
					outputIndex: this.currentOutputIndex,
					index,
					key,
				};
			},
			getOutputName (outputIndex: number) {
				if (this.node === null) {
					return outputIndex + 1;
				}

				const nodeType = this.nodeType;
				if (!nodeType || !nodeType.outputNames || nodeType.outputNames.length <= outputIndex) {
					return outputIndex + 1;
				}

				return nodeType.outputNames[outputIndex];
			},
			convertPath (path: string): string {
				// TODO: That can for sure be done fancier but for now it works
				const placeholder = '*___~#^#~___*';
				let inBrackets = path.match(/\[(.*?)\]/g);

				if (inBrackets === null) {
					inBrackets = [];
				} else {
					inBrackets = inBrackets.map(item => item.slice(1, -1)).map(item => {
						if (item.startsWith('"') && item.endsWith('"')) {
							return item.slice(1, -1);
						}
						return item;
					});
				}
				const withoutBrackets = path.replace(/\[(.*?)\]/g, placeholder);
				const pathParts = withoutBrackets.split('.');
				const allParts = [] as string[];
				pathParts.forEach(part => {
					let index = part.indexOf(placeholder);
					while(index !== -1) {
						if (index === 0) {
							allParts.push(inBrackets!.shift() as string);
							part = part.substr(placeholder.length);
						} else {
							allParts.push(part.substr(0, index));
							part = part.substr(index);
						}
						index = part.indexOf(placeholder);
					}
					if (part !== '') {
						allParts.push(part);
					}
				});

				return '["' + allParts.join('"]["') + '"]';
			},
			handleCopyClick (commandData: { command: string }) {
				const isNotSelected = this.selectedOutput.path === deselectedPlaceholder;
				const selectedPath = isNotSelected ? '[""]' : this.selectedOutput.path;

				let selectedValue = this.selectedOutput.value;
				if (isNotSelected) {
					if (this.hasPinData) {
						selectedValue = this.clearJsonKey(this.pinData as object);
					} else {
						selectedValue = this.convertToJson(this.getNodeInputData(this.node, this.runIndex, this.currentOutputIndex));
					}
				}

				const newPath = this.convertPath(selectedPath);

				let value: string;
				if (commandData.command === 'value') {
					if (typeof selectedValue === 'object') {
						value = JSON.stringify(selectedValue, null, 2);
					} else {
						value = selectedValue.toString();
					}

					this.$showToast({
						title: this.$locale.baseText('runData.copyValue.toast'),
						message: '',
						type: 'success',
						duration: 2000,
					});
				} else {
					let startPath = '';
					let path = '';
					if (commandData.command === 'itemPath') {
						const pathParts = newPath.split(']');
						const index = pathParts[0].slice(1);
						path = pathParts.slice(1).join(']');
						startPath = `$item(${index}).$node["${this.node!.name}"].json`;

						this.$showToast({
							title: this.$locale.baseText('runData.copyItemPath.toast'),
							message: '',
							type: 'success',
							duration: 2000,
						});
					} else if (commandData.command === 'parameterPath') {
						path = newPath.split(']').slice(1).join(']');
						startPath = `$node["${this.node!.name}"].json`;

						this.$showToast({
							title: this.$locale.baseText('runData.copyParameterPath.toast'),
							message: '',
							type: 'success',
							duration: 2000,
						});
					}
					if (!path.startsWith('[') && !path.startsWith('.') && path) {
						path += '.';
					}
					value = `{{ ${startPath + path} }}`;
				}

				const copyType = {
					value: 'selection',
					itemPath: 'item_path',
					parameterPath: 'parameter_path',
				}[commandData.command];

				this.$telemetry.track('User copied ndv data', {
					node_type: this.activeNode.type,
					session_id: this.sessionId,
					run_index: this.runIndex,
					view: this.displayMode,
					copy_type: copyType,
					workflow_id: this.$store.getters.workflowId,
					pane: 'output',
					in_execution_log: this.isReadOnly,
				});

				this.copyToClipboard(value);
			},
			refreshDataSize () {
				// Hide by default the data from being displayed
				this.showData = false;

				// Check how much data there is to display
				const inputData = this.getNodeInputData(this.node, this.runIndex, this.currentOutputIndex);

				const offset = this.pageSize * (this.currentPage - 1);
				const jsonItems = inputData.slice(offset, offset + this.pageSize).map(item => item.json);

				this.dataSize = JSON.stringify(jsonItems).length;

				if (this.dataSize < this.MAX_DISPLAY_DATA_SIZE) {
					// Data is reasonable small (< 200kb) so display it directly
					this.showData = true;
				}
			},
			onRunIndexChange(run: number) {
				this.$emit('runChange', run);
			},
			enableNode() {
				if (this.node) {
					const updateInformation = {
						name: this.node.name,
						properties: {
							disabled: !this.node.disabled,
						},
					};

					this.$store.commit('updateNodeProperties', updateInformation);
				}
			},
		},
		watch: {
			node() {
				this.init();
			},
			jsonData (value: IDataObject[]) {
				this.refreshDataSize();

				const hasSeenPinDataTooltip = localStorage.getItem(LOCAL_STORAGE_PIN_DATA_DISCOVERY_NDV_FLAG);
				if (!hasSeenPinDataTooltip) {
					this.showPinDataDiscoveryTooltip(value);
				}
			},
			binaryData (newData: IBinaryKeyData[], prevData: IBinaryKeyData[]) {
				if (newData.length && !prevData.length && this.displayMode !== 'binary') {
					this.switchToBinary();
				}
				else if (!newData.length && this.displayMode === 'binary') {
					this.onDisplayModeChange('table');
				}
			},
		},
	});
