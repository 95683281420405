


























import Vue from 'vue';

export default Vue.extend({
	name: 'ParameterOptions',
	props: [
		'parameter',
		'isReadOnly',
		'value',
		'showOptions',
	],
	computed: {
		isDefault (): boolean {
			return this.parameter.default === this.value;
		},
		shouldShowOptions (): boolean {
			if (this.isReadOnly === true) {
				return false;
			}

			if (this.parameter.type === 'collection' || this.parameter.type === 'credentialsSelect') {
				return false;
			}

			if (this.showOptions === true) {
				return true;
			}

			return false;
		},
		selectedView () {
			if (this.isValueExpression) {
				return 'expression';
			}

			return 'fixed';
		},
		isValueExpression () {
			if (this.parameter.noDataExpression === true) {
				return false;
			}
			if (typeof this.value === 'string' && this.value.charAt(0) === '=') {
				return true;
			}
			return false;
		},
		hasRemoteMethod (): boolean {
			return !!this.getArgument('loadOptionsMethod') || !!this.getArgument('loadOptions');
		},
		actions (): Array<{label: string, value: string, disabled?: boolean}> {
			const actions = [
				{
					label: this.$locale.baseText('parameterInput.resetValue'),
					value: 'resetValue',
					disabled: this.isDefault,
				},
			];

			if (this.hasRemoteMethod) {
				return [
					{
						label: this.$locale.baseText('parameterInput.refreshList'),
						value: 'refreshOptions',
					},
					...actions,
				];
			}

			return actions;
		},
	},
	methods: {
		onMenuToggle(visible: boolean) {
			this.$emit('menu-expanded', visible);
		},
		onViewSelected(selected: string) {
			if (selected === 'expression' ) {
				this.$emit('optionSelected', this.isValueExpression? 'openExpression': 'addExpression');
			}

			if (selected === 'fixed' && this.isValueExpression) {
				this.$emit('optionSelected', 'removeExpression');
			}
		},
		getArgument (argumentName: string): string | number | boolean | undefined {
			if (this.parameter.typeOptions === undefined) {
				return undefined;
			}

			if (this.parameter.typeOptions[argumentName] === undefined) {
				return undefined;
			}

			return this.parameter.typeOptions[argumentName];
		},
	},
});
