












import mixins from 'vue-typed-mixins';
import { copyPaste } from './mixins/copyPaste';
import { showMessage } from './mixins/showMessage';

export default mixins(copyPaste, showMessage).extend({
	props: {
		label: {
			type: String,
		},
		hint: {
			type: String,
		},
		value: {
			type: String,
		},
		copyButtonText: {
			type: String,
			default(): string {
				return this.$locale.baseText('generic.copy');
			},
		},
		toastTitle: {
			type: String,
			default(): string {
				return this.$locale.baseText('generic.copiedToClipboard');
			},
		},
		toastMessage: {
			type: String,
		},
		collapse: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'large',
		},
	},
	methods: {
		copy(): void {
			this.$emit('copy');
			this.copyToClipboard(this.value);

			this.$showMessage({
				title: this.toastTitle,
				message: this.toastMessage,
				type: 'success',
			});
		},
	},
	mounted() {
		this.$externalHooks().run('copyInput.mounted', { copyInputValueRef: this.$refs.copyInputValue });
	},
});
