import { render, staticRenderFns } from "./CodeEditor.vue?vue&type=template&id=55f68508&scoped=true&"
import script from "./CodeEditor.vue?vue&type=script&lang=ts&"
export * from "./CodeEditor.vue?vue&type=script&lang=ts&"
import style0 from "./CodeEditor.vue?vue&type=style&index=0&id=55f68508&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55f68508",
  null
  
)

export default component.exports